<template>
  <div class="container">
    <div class="pa-20">
      <div class="flex f-center f-between">
        <img class="logo" src="~@/assets/img/logo.png" />
        <div class="flex f-center back-box" @click="$router.replace('/')">
          <img class="back" src="~@/assets/img/back.png"/>
          <span>返回</span>
        </div>
      </div>
      <div class="t-center fon-40  ma-t-80">
        <!-- 访客证号：{{info.cardNo}}的访客再次入园，请确认   -->
      </div>
      <div class="box4 ma-t-80">
        <div class="fon-40 pa-tb-30 t-center">
        访客证号：{{info.cardNo}}的访客再次入园，请确认  
          
        </div>
        <div class="form pa-tb-20">
          <div class="width60 ma-lr-auto">
            <van-button type="primary" size="normal" class="width100" color="#003AA9" @click="submit">确认</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    return {
      info: {
        epidInfo :{}
      },
      id: 0,
      intvConfirm: true, //受访人意见
      temperature: '0', // 体温
      parkingNoList: '', // 停车证号码
      cardNo: '', //访客证号码
    };
  },
  created() {
    _this = this;
    _this.id = _this.$route.query.id
    _this.loadData()
  },
  watch: {
    $route(to, from) {
      //  window.location.reload(); //监测到路由发生跳转时刷新一次页面
   },
  },
  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    next()
  },
  methods: {
    // 加载数据
    loadData() {
      _this.$request({
        url: '/security/query',
        method: 'GET',
        params:{
          code: _this.id,
          queryType:0
        }
      }).then((res) => {
        _this.info = res
      });
    },

    // 确认
    submit() {
      if (_this.$validator.isEmpty(_this.$store.state.entranceId)) {
        return _this.$dialog.alert({
          title: '提示',
          message: '请先绑定通道',
        }).then(() => {
          _this.$router.push("/setting")
        })
      }

      let form = {
        id: _this.info.id,
        intvConfirm: _this.intvConfirm ? 1 : 0,
        entranceId: _this.$store.state.entranceId,
        members: [{
          id: _this.info.id,
          cardNo: _this.info.cardNo,
          temperature: _this.temperature,
        }],
        parkingNoList: _this.info.parkingNoList
      }
      
      _this.$request({
        url: '/security/in',
        method: 'POST',
        data: form
      }).then(() => {
        _this.$router.replace('/enter/success') 
      });
    },


  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/css/style.less';

.overlays{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .overlay-box{
    padding: 20px 40px;
    border-radius: 5px;
    background: #FFFFFF;
    text-align: left;
    font-size: 20px;
  }
}
</style>